// function usePageIdentifier() {
//   // 1- get page title
//   const homePageUrl = "https://www.thegoodguys.com.au";
//   const homePageUtlTst = "https://tstauthv8.thegoodguys.com.au";
//   const homePageUtlPrd = "https://prdauthv8.thegoodguys.com.au";
//   const pageFullUrl = document.querySelector("link[rel='canonical']").getAttribute("href");
//   let pageTitle = null;
//   if (
//     pageFullUrl === homePageUrl ||
//     pageFullUrl === homePageUtlTst ||
//     pageFullUrl === homePageUtlPrd
//   ) {
//     pageTitle = "home-page";
//   } else {
//     pageTitle = pageFullUrl.substring(
//       pageFullUrl.lastIndexOf("/") + 1,
//       pageFullUrl.length
//     );
//     //console.log(pageTitle)
//   }
//    return {
//     pageTitle,
//   };
// }

// export default usePageIdentifier;
import { useState, useEffect } from "react";
import useHttp from "./../hooks/useHttp";


 function usePageIdentifier() {
  const { error, sendRequest: fetchTasks } = useHttp();

   const [pageTitle, setPageTitle] = useState()
   /////////////////////////////////////////////////////////////////////////
  function getBreadcrumbInfo() {
    const el = document.getElementById('widget_breadcrumb');
    const list = el.getElementsByTagName('li');
    const page_type = list.length -1;
    let list_arr = [];
  
    for (let item of list){
      if (item.childNodes && item.childNodes.length > 1) {
        // console.log(cleanup(item.querySelector("[property='name']").innerHTML));
        list_arr.push(cleanup(item.querySelector("[property='name']").innerHTML));
      } else {
        // console.log(cleanup(item.innerHTML));
        list_arr.push(cleanup(item.innerHTML));
      }
    }
    
    function cleanup(str){
      return str.replaceAll("&amp;", "&");
    }
    //console.log(list_arr)
    return {
      array: list_arr,
      category_name: cleanup(list[page_type].innerHTML),
      parent_category_name: cleanup(list[page_type-1].querySelector("[property='name']").innerHTML),
      page_type: page_type
    }
  }
  
  //let firebaseUrl = "https://rich-navigation-v2-tst.firebaseio.com/Slider_Matrix.json" // TST
  let firebaseUrl = 'https://rich-navigation-v2.firebaseio.com/Slider_Matrix.json' // PRD
  // fetch('https://rich-navigation-v2-tst.firebaseio.com/Slider_Matrix.json')
  //   .then(response => {
  //     if(!response.ok) {
  //       throw new Error(`HTTP error: ${response.status}`);
  //     }
  //     return response.json();
  //   })

    const dataValidator = (Obj) => {
      //console.table(Obj);
      //console.log(Obj);
      let arr = getBreadcrumbInfo().array;
      arr = arr.reverse();
      arr.pop(); //remove 'Home'
      
      //console.log(arr);
  
      let match = false;
      let pageTitleAddress;
  
      for (let str of arr) {
        if (!match){
          str = '"' + str + '"';
          //console.log({str})
          for (let key of Object.keys(Obj)) {
            // console.log(key, Obj[key].Inclusion);
            // console.log("exclusions: ", Obj[key].Exclusions);
            if (Obj[key].Inclusion.indexOf(str) > -1) {
              //console.log("match: ", key);
              //console.log({str})
              //console.log("inclusion: ", Obj[key].Inclusion);
              //console.log(Obj[key].Inclusion.indexOf(str));
              let excluded = false;
              for (let str2 of arr) {
                str2 = '"' + str2 + '"';
                //check all strings against excl to get the okay
                if (Obj[key].Exclusions && Obj[key].Exclusions.indexOf(str2) > -1) {
                  //console.log("excluded: ", str2);
                  excluded = true;
                  break;
                }
              }
              if (!excluded){
                pageTitleAddress = key;
                //console.log('pageTitleAddress: ', pageTitleAddress)
                match = true;
               
                setPageTitle(pageTitleAddress);
              }
            }
            // console.log("==================================")
          }
        }
      }
      if (match = false){
        console.log("rich-nav image slider: no match for category(ies) found in data");
      }
    }
 

    useEffect(() => {
      fetchTasks({ url:  firebaseUrl}, dataValidator);
    }, [fetchTasks,firebaseUrl]);
 
    return {
     pageTitle,
     error
   };
 }

 export default usePageIdentifier;