import React, { Fragment } from "react";
import "./slider-item.css";
import { getTaggingFunc } from "../../helpers/dataLayerData";
//import useWindowSize from "../../hooks/useWindowSize";

const ContentSliderItem = (props) => {
  //const pageTypeIdentifier = props.drawerTypeTagging
  //const url = props.redirectURL
  const imageUrl = props.imageUrl
  const videoDescription = props.videoDescription
  //const mobileImageUrl = props.mobileImageUrl
  const imageAlt = props.imageAlt
  //const screenStatus = useWindowSize();
  //const eventAction = 'Link'
  //const desc = props.description
  //const pos = props.position
  //const type= props.drawerType //normal or slide or landscape
  //const eventLabel = `${imageAlt} Position ${'test'}`
  //var imageUrlValidation
  // if(screenStatus === 'mobile'){
  //   if(mobileImageUrl === '') {
  //     imageUrlValidation = imageUrl
  //   }else{
  //     imageUrlValidation = mobileImageUrl
  //   }
  // }else {
  //   if(imageUrl === '') {
  //     imageUrlValidation = mobileImageUrl
  //   }else{
  //     imageUrlValidation = imageUrl
  //   }
  // }
  //console.log(props.position)
  return (
    <Fragment>
    <a
        className="slideWrapper text-left text-primary"
        //href={url}
        //onClick={props.modalShowHandler}
        onClick={
          () => {
          props.modalShowHandler()
          getTaggingFunc(videoDescription,props.position + 1)
          props.selectedVideoIndex(props.position)
        }
          
        }
      >
        <img
          className="noSelect slideHero border-graylight lazy"
          src={imageUrl}
          alt={imageAlt}
          loading="lazy"
          style={{height: '123px', width:'218px'}}
        />
        {/* <p className="noSelect catalogueTitle">
          {desc}
        </p> */}
      </a>
    </Fragment>
  );
};

export default ContentSliderItem;
//getTaggingFunc(pageUrl, categoryLevel, alt, positionIndex) 

