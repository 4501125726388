import React, { useState, useEffect } from "react";
import VideoImage from "../videoImages/videoImages";
import PlayListLayout from "../../layout/playListLayout";
import "./playList.css";
import useWindowSize from "../../hooks/useWindowSize";
import PlayListMobileLayout from "../../layout/playListMobileLayout";
//import { getYTVideoPlayTaggingFunc } from "../../helpers/dataLayerData";
import IframGenerator from "../iframGenerator/iframGenerator";

function PlayList(props) {
  const screenStatus = useWindowSize();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState();
  const [slectedVideoDescription, setSlectedVideoDescription] = useState()
  const [videoSrc, setVideoSrc] = useState();
  //const [overlayDisplay, setOverlayDisplay] = useState("block");
  const videoListStartFrom = props.selectedVideoIndex;
  //console.log('videoListStartFrom: ',videoListStartFrom)
  const data = props.contentSliderDataArr;
  //console.log('data: ',data)
  //console.log('selectedVideoIndex: ',selectedVideoIndex);

  let YTSrc = null;
  let YTVideoTaggingDescription = null
  if(selectedVideoIndex && selectedVideoIndex !== null){
    YTSrc = selectedVideoIndex
  }else{
    YTSrc = data[videoListStartFrom].videoURL
    YTVideoTaggingDescription = data[videoListStartFrom].videoDescription
  }
    
  slectedVideoDescription && slectedVideoDescription != null ? YTVideoTaggingDescription = slectedVideoDescription : YTVideoTaggingDescription = data[videoListStartFrom].videoDescription

  useEffect(() => {
    //console.log('YTSrc: ', YTSrc)
    setVideoSrc(YTSrc);
    //setSlectedVideoDescription(YTVideoTaggingDescription)
  }, [YTSrc]);

  const selectedVideoIndexFunc = (x,y) => {
    //setOverlayDisplay("block")
    //console.log(x,y)
    setSelectedVideoIndex(x);
    setSlectedVideoDescription(y)
    //console.log(i);
  };

  //let overlayDisplay = 'block'
  //const playVideo = (a) => {
    //console.log('playVideo: ', a)
    //console.log('selectedVideoIndex: ', a)
    //console.log(videoSrc + "?autoplay=1")
    //let autoplaySrc = null;
    // if (screenStatus !== "mobile") {
      //autoplaySrc = videoSrc + "?autoplay=1";
      //setVideoSrc(autoplaySrc);
      //setSlectedVideoDescription(a)
    // } else {
    //   setVideoSrc(videoSrc);
    // }
    //getYTVideoPlayTaggingFunc(a);
    //setOverlayDisplay("none");
  //};
  return (
    <div>
      <div className="videoModalHeader">
        <button
          type="button"
          className="closeModal text-normal"
          onClick={props.modalClose}
        >
          <span>×</span>
        </button>
        <h3>Video Gallery</h3>
      </div>
      <div className="videoModalWrapper">
        {screenStatus === "mobile" ? (
          <PlayListMobileLayout elClass={`videosWrapper pull-left`} numberOfSliders = {props.numberOfSliders}>
            {data.map((slideInfo, index) => {
              return (
                <VideoImage
                  key={index}
                  videoUrl={slideInfo.videoURL}
                  imageUrl={slideInfo.thumbnail}
                  videoModalText={slideInfo.videoModalText}
                  videoModalURL={slideInfo.videoModalURL}
                  //mobileImageUrl={slideInfo.mobileImageUrl}
                  imageAlt={slideInfo.thumbnailAlt}
                  videoTime={slideInfo.videoTime}
                  videoDescription={slideInfo.videoDescription}
                  //modalShowHandler={props.modalShowHandler}
                  //redirectURL={slideInfo.redirectURL}
                  //description = {slideInfo.description}
                  //position={slideInfo.id}
                  //drawerTypeTagging = "Bottom"
                  //drawerType={drawerType}
                  position={index}
                  selectedVideoIndex={selectedVideoIndexFunc}
                />
              );
            })}
          </PlayListMobileLayout>
        ) : (
          <PlayListLayout elClass={`videosWrapper pull-left`} numberOfSliders= {props.numberOfSliders}>
            {data.map((slideInfo, index) => {
              return (
                <VideoImage
                  key={index}
                  videoUrl={slideInfo.videoURL}
                  imageUrl={slideInfo.thumbnail}
                  videoModalText={slideInfo.videoModalText}
                  videoModalURL={slideInfo.videoModalURL}
                  //mobileImageUrl={slideInfo.mobileImageUrl}
                  imageAlt={slideInfo.thumbnailAlt}
                  videoTime={slideInfo.videoTime}
                  videoDescription={slideInfo.videoDescription}
                  //modalShowHandler={props.modalShowHandler}
                  //redirectURL={slideInfo.redirectURL}
                  //description = {slideInfo.description}
                  //position={slideInfo.id}
                  //drawerTypeTagging = "Bottom"
                  //drawerType={drawerType}
                  position={index}
                  selectedVideoIndex={selectedVideoIndexFunc}
                />
              );
            })}
          </PlayListLayout>
        )}
        <div className="videoPlayBoxWrapper pull-right">
        { videoSrc && videoSrc !== undefined && 
            <IframGenerator 
              videoSrc={videoSrc} 
              eventLable={YTVideoTaggingDescription} 
              videoTaggingDataGetAfterModalCloesedFun={props.videoTaggingDataGetAfterModalCloesedFun}
            />}
        </div>
      </div>
    </div>
  );
}

export default PlayList;
