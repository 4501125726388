import React, { Fragment } from "react";
//import useWindowSize from '../../hooks/useWindowSize';
import Carousel from "react-multi-carousel";
import ContentSliderItem from "../../components/slider-item/slider-item";
import { getTaggingFunc } from "../../helpers/dataLayerData";

import "./slider.css";

const ContentSlider = (props) => {

  //const screenStatus = useWindowSize()
  //const drawerType = props.drawerType
  const dataArr = props.contentSliderDataArr
  //console.log('dataArr: ============> ', dataArr)

//items: 3,
//partialVisibilityGutter: 40

  const responsiveMode = {
    desktop: {
      breakpoint: { max: 3000, min: 991 },
      items: 1,
      partialVisibilityGutter: 40
      //slidesToSlide: 1, // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 991, min: 768 },
      items: 2.5,
      partialVisibilityGutter: 40
      //slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2.5,
      //slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 375 },
      items: 1.5,
      //slidesToSlide: 1, // optional, default to 1.
      //partialVisibilityGutter: 100
    },
    mobileS: {
      breakpoint: { max: 375, min: 280 },
      items: 1.4,
      //slidesToSlide: 1, // optional, default to 1.
    },
    mobileXS: {
      breakpoint: { max: 280, min: 0 },
      items: 1,
      //slidesToSlide: 1, // optional, default to 1.
    },
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
     const {
         carouselState: { currentSlide }
     } = rest;
    // const currentSlideNumber = currentSlide + 1
    return <button
        aria-label="Go to previous slide"
        className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--right`}
        type="button"
        onClick={() => {
            onClick()
            getTaggingFunc('Scroll to Browse','Arrow Next')
        }
        }
    ></button>
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
      carouselState: { currentSlide }
  } = rest;
 // const currentSlideNumber = currentSlide + 1
 return <button
     aria-label="Go to previous slide"
     className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--left`}
     type="button"
     onClick={() => {
         onClick()
         getTaggingFunc('Scroll to Browse','Arrow Prev')
     }
     }
 ></button>
};
//onclick="dataLayer.push({'event': 'category_nav_widget','TGGEventCategory':'Category Nav Widget','TGGEventAction':'Video - Scroll to Browse' ,'TGGEventLabel':'Arrow Next});"


  //Center mode modification
  // const numberOfSliders = dataArr.length
  // //console.log(numberOfSliders)
  // let cssModifier = false
  // if(numberOfSliders < 4 && screenStatus === 'desktop'){
  //   cssModifier = true
  // }else if(numberOfSliders < 3 && screenStatus === 'laptop'){
  //   cssModifier = true
  // }else if(numberOfSliders < 3 && screenStatus === 'tablet'){
  //   cssModifier = true
  // }
  //console.log(cssModifier)
  // let responsiveSetting = responsive
  // drawerType === 'landscape' ? responsiveSetting = responsiveLandscapeType : responsiveSetting = responsive
  return (
    <Fragment>
      <Carousel
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsiveMode}
        ssr={true} // means to render carousel on server-side.
        infinite={false}
        //autoPlay={this.props.deviceType !== "mobile" ? true : false}
        //autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="transform 1000ms cubic-bezier(0.165, 0.84, 0.44, 1) 100ms"
        //transitionDuration={500}
        //containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile","tablet","mobileS","mobileXS"]}
        //deviceType={this.props.deviceType}
        //dotListClass="custom-dot-list-style"
        //itemClass="carousel-item-padding-40-px"
        focusOnSelect={false}
        //minimumTouchDrag={150}
        partialVisible={true}
        // containerClass={`${cssModifier ? "react-multi-carousel-list-modifier" : null}`}
      >
       {dataArr.map((slideInfo, index) => {
         //console.log(slideInfo)
                return <ContentSliderItem
                    key={index}
                    imageUrl={slideInfo.thumbnail}
                    mobileImageUrl={slideInfo.mobileImageUrl}
                    imageAlt={slideInfo.thumbnailAlt}
                    modalShowHandler={props.modalShowHandler}
                    position={index}
                    selectedVideoIndex={props.selectedVideoIndex}
                    videoDescription={slideInfo.videoDescription}
                    //redirectURL={slideInfo.redirectURL}
                    //description = {slideInfo.description}
                    //position={slideInfo.id}
                    //drawerTypeTagging = "Bottom"
                    //drawerType={drawerType}
                />
            })}
      </Carousel>
    </Fragment>
  );
};
export default React.memo(ContentSlider);
