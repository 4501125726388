import React, {useEffect} from 'react'
import { getYTVideoPlayTaggingFunc } from "./../../helpers/dataLayerData";

function IframGenerator(props) {
  //const [modalClosed, setModalClosed] = useState()
    const tggEventLable = props.eventLable
    let videoUrl = props.videoSrc
    let yTVideoId
    if(videoUrl !== undefined){
        yTVideoId = videoUrl.substring(videoUrl.lastIndexOf('/') + 1)
        //console.log(yTVideoId)
        //setVideoId(yTVideoId)
    }

 // This function creates an <iframe> (and YouTube player)
 // after the API code downloads.
 var player;
 function onYouTubeIframeAPIReady(id) {
    
   player = new window.YT.Player('richNavPlayerContent', {
    width:"100%",
    height:"100%",
     videoId: id,
     playerVars: {
       'playsinline': 1,
     },
     events: {
       'onReady': onPlayerReady,
       'onStateChange': onPlayerStateChange
     }
   });
 }
  //console.log(player)

 // The API will call this function when the video player is ready.
  function onPlayerReady(event) {
    console.log('Video is ready to play')
    //console.log('modalStatus: ', props.modalStatus)
    //event.target.playVideo();
  }

 // The API calls this function when the player's state changes.
 // The function indicates that when playing a video (state=1),
 //  the player should play for six seconds and then stop.
 //var done = false;
 var videoStatus
 var videoTime
 let videoDuration
 var percentageOfVideoDuration
 function onPlayerStateChange(event) {
   //console.log('modalClosed: ', modalClosed)
  videoDuration = player.playerInfo.duration
  //console.log('playing Info: ', player.playerInfo)
  //console.log('Video Duration: ', player.playerInfo.duration )
    if(player){
      if(player.playerInfo.playerState === 0 ){
        videoStatus = 'ended'
        videoTime = player.playerInfo.currentTime
        //console.log('videoStatus: ', videoStatus)
        //console.log('ended time: ', player.playerInfo.currentTime)
        percentageOfVideoDuration = (videoTime * 100)/videoDuration
        //console.log('percentageOfVideoDuration: %',~~percentageOfVideoDuration)
        getYTVideoPlayTaggingFunc(tggEventLable, videoStatus, ~~percentageOfVideoDuration);
        //props.videoTaggingDataGetAfterModalCloesedFun(tggEventLable, 'stopped' , ~~percentageOfVideoDuration)
      }else if(player.playerInfo.playerState === 1){
        videoStatus = 'play'
        //modalClosed && modalClosed === 'Modal Closed' ? videoStatus = 'stopped' : videoStatus = 'play'
        videoTime = player.playerInfo.currentTime
        //console.log('videoStatus: ', videoStatus)
        //console.log('playing time: ', player.playerInfo.currentTime)
        percentageOfVideoDuration = (videoTime * 100)/videoDuration
        //console.log('percentageOfVideoDuration: %',~~percentageOfVideoDuration)
        props.videoTaggingDataGetAfterModalCloesedFun(tggEventLable, videoStatus , ~~percentageOfVideoDuration)
        getYTVideoPlayTaggingFunc(tggEventLable, videoStatus, ~~percentageOfVideoDuration);
      }else if(player.playerInfo.playerState === 2){
        videoStatus = 'pause'
        videoTime = player.playerInfo.currentTime
        //console.log('videoStatus: ', videoStatus)
        //console.log('paused time: ', player.playerInfo.currentTime)
        percentageOfVideoDuration = (videoTime * 100)/videoDuration
        //console.log('percentageOfVideoDuration: %',~~percentageOfVideoDuration)
        getYTVideoPlayTaggingFunc(tggEventLable, videoStatus, ~~percentageOfVideoDuration);
        //props.videoTaggingDataGetAfterModalCloesedFun(tggEventLable, 'stopped' , ~~percentageOfVideoDuration)
      }else if(player.playerInfo.playerState === 3){
        videoStatus = 'buffering'
        //console.log('videoStatus: ', videoStatus)
        //console.log('buffering time: ', player.playerInfo.currentTime)  
      }else if(player.playerInfo.playerState === -1){
        videoStatus = 'unstarted'
        //console.log('videoStatus: ', videoStatus)
        //console.log('unstarted time: ', player.playerInfo.currentTime)
      }
    }
    //console.log('Pause time: ', player.playerInfo.currentTime)
    
  
  
//    if (event.data == window.YT.PlayerState.PLAYING && !done) {
//      setTimeout(stopVideo, 6000);
//      done = true;
//    }
 }
//  function stopVideo() {
//    console.log('play stoped')
//    player.stopVideo();
//  }
 

 let orgSrc,fixSrcPart,dybamicSrcPart

 useEffect(() => {
  //console.log('Hi')
          onYouTubeIframeAPIReady(yTVideoId) 
          //console.log(document.getElementById('richNavPlayerContent').src)
          orgSrc =  document.getElementById('richNavPlayerContent').src
          fixSrcPart = orgSrc.substring(orgSrc.indexOf("?"))
          dybamicSrcPart = `https://www.youtube.com/embed/${yTVideoId}${fixSrcPart}`
          document.getElementById('richNavPlayerContent').src = dybamicSrcPart

          return () => {
            //console.log('BYE')
            //console.log('modalStatus: ', props.modalStatus)
            //setModalClosed('Modal Closed')
            //console.log(videoStatus)
            if(videoStatus === 'play'){
              onPlayerStateChange()
            }
            
            //getYTVideoPlayTaggingFunc(tggEventLable, 'stopped' , ~~percentageOfVideoDuration);
          }
    
 }, [onYouTubeIframeAPIReady, props.videoSrc])
  ////////////////////////////////////////////////////////////////////////////////////////////////
    //console.log(props.videoSrc)
    return (
        <div id="richNavPlayerContent" className="videoPlayBox"></div>
    )
}

export default IframGenerator
