import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import useHttp from "./hooks/useHttp";
import ContentSlider from "./containers/slider/slider";
//import useWindowSize from "./hooks/useWindowSize";
import usePageIdentifier from "./hooks/usePageIdentifier";
import RichNavWidgetLayout from "./layout/richNavWidgetLayout";
import Modal from "./UI/Modal/Modal";
import PlayList from "./components/playList/playList";
import { getYTVideoPlayTaggingFunc } from "./helpers/dataLayerData";
//import { getTaggingFunc } from "./helpers/dataLayerData";
import "./App.css";
//import PlayListLayout from "./layout/playListLayout";

function App() {
  const [contentSliderDataArr, setContentSliderDataArr] = useState();
  const [PageVisibilityValidation, setPageVisibilityValidation] = useState();
  const [videoSectionTitle, setVideoSectionTitle] = useState();
  const [videoSectiontTitleColor, setVideoSectiontTitleColor] = useState();
  const [gradientColor, setGradientColor] = useState();
  const [videoSequenceList, setVideoSequenceList] = useState()
  const [modalShow, setModalShow] = useState(false);
  // function loadVideo() {
  //   console.log('(window.YT: ',window.YT);
  // }
  // useYoutube(loadVideo);
  //const [validDateToDiplay, setValidDateToDiplay] = useState(false);
  const { pageTitle } = usePageIdentifier();
  const { error, sendRequest: fetchTasks } = useHttp();
  //const screenStatus = useWindowSize();
  //let Url = "https://rich-navigation-v2-tst.firebaseio.com/Slider_Video/"; //TST 2
  let Url = "https://rich-navigation-v2.firebaseio.com/Slider_Video/" //PROD 2
  let firbaseUrl = null;
  firbaseUrl = Url + `${pageTitle}.json`;
  //console.log(firbaseUrl);
  const dataValidator = (Obj) => {
    //console.log("transformeddataValidator: ", Obj);
    //console.log('Video_section_gradientColor: ',Obj.Video_section_gradientColor)

    setVideoSectionTitle(Obj.Video_section_title);
    setGradientColor(Obj.Video_section_gradientColor);
    setVideoSectiontTitleColor(Obj.Video_section_title_colour);
    let pageVisibility = false;
    //console.log('Obj.Page_Title: ', Obj.Page_Title , typeof(Obj.Page_Title), Obj.Page_Title.length)
    //console.log('Obj.Video: ---------------------------------------------', Obj.Video)
    Obj.Video && Obj.Video.length === 0
      ? (pageVisibility = false)
      : (pageVisibility = true);
    //console.log("Page Validation: ", pageVisibility);
    //console.log(Obj.Drawer_TimingUp)
    setPageVisibilityValidation(pageVisibility);
    //----------------------------------------------------------------------------------
    //let startDate;
    //let endDate;
    //const currentDate = new Date();
    // Obj.Drawer_TimingUp === ""
    //   ? (startDate = currentDate)
    //   : (startDate = new Date(Obj.Drawer_TimingUp));
    // Obj.Drawer_TimingDown === ""
    //   ? (endDate = new Date("2/20/2026"))
    //   : (endDate = new Date(Obj.Drawer_TimingDown));
    // const dateValidtion = currentDate <= endDate && currentDate >= startDate;
    //-----------------------------------------------------------------------------------
    const contentSliderData = Obj.Video;
    //console.log('contentSliderData: --> ', contentSliderData);
    //const contentSliderDataArr = _.values(contentSliderData);
    //console.log(contentSliderDataArr)
    const contentSliderDataArr = _.without(contentSliderData, null);
    //console.log(contentSliderDataArr);
    //_.without(contentSliderDataArr, null)
    //console.log(contentSliderDataArr)
    _.remove(contentSliderDataArr, function (e) {
      //console.log("videoDescription: ", e.videoDescription);
      return (
        e.videoDescription === undefined ||
        e.videoURL === undefined ||
        e.thumbnail === undefined ||
        e.id === undefined
      );
    });

       //console.log('contentSliderDataArr.length: ',contentSliderDataArr.length);
    setContentSliderDataArr(contentSliderDataArr);
  };

  useEffect(() => {
    fetchTasks({ url: firbaseUrl }, dataValidator);
  }, [fetchTasks, firbaseUrl, PageVisibilityValidation]);

 
  const widgetDisplayCondition = PageVisibilityValidation && !error;
  //console.log('widgetDisplayCondition: ', widgetDisplayCondition)
  //contentSliderDataArr &&
  //contentSliderDataArr.length > 1 &&
  //validDateToDiplay
  let tggEventLable
  let videoStatus
  let percentageOfVideoDuration
  //console.log('modalShow: ', modalShow)
  const videoTaggingDataGetAfterModalCloesedFun = (x,y,z)=>{
    //console.log('modalShow: ', modalShow)
    //console.log(x , y , z)
    tggEventLable = x
    videoStatus = y
    percentageOfVideoDuration = z
  }

  const modalShowHandler = () => {
    setModalShow(true);
    window.scrollTo(0, 0);
  };

  const selectedVideoIndex =(i)=>{
    setVideoSequenceList(i)
    //console.log('selectedVideoIndex: ', i)
  }

  const modalHideHandler = () => {
    setModalShow(false);
    setTimeout(() => {
      //console.log('videoStatus: ', videoStatus)
      if(videoStatus === 'play' && percentageOfVideoDuration !== 0){
        getYTVideoPlayTaggingFunc(tggEventLable , 'close', percentageOfVideoDuration)
      }
    }, 200);
   
  };
  //console.log("VideoSectionTitle: ", videoSectionTitle);
  let videoTitleColor;
  videoSectiontTitleColor && videoSectiontTitleColor !== undefined
    ? (videoTitleColor = videoSectiontTitleColor)
    : (videoTitleColor = "black");

  return (
    <Fragment>
      {widgetDisplayCondition ? (
        <div>
          <h4
            className="widgetTitle text-size-115 text-bold"
            style={{ color: videoTitleColor }}
          >
            {videoSectionTitle && videoSectionTitle !== null
              ? videoSectionTitle
              : "Watch helpful tips and guides"}
          </h4>
          <div className="richNavWrapper text-white pos-relative">
            <RichNavWidgetLayout
              elClass={`richNavWidgetWrapper`}
              gradientColor={gradientColor}
              numberOfSliders = {contentSliderDataArr.length}
            >
              <ContentSlider
                contentSliderDataArr={contentSliderDataArr}
                modalShowHandler={modalShowHandler}
                selectedVideoIndex={selectedVideoIndex}
                videoSequenceList={videoSequenceList}
              />
            </RichNavWidgetLayout>
          </div>
        </div>
      ) : null}
      {modalShow && (
        <Modal modalClose={modalHideHandler}>
          <PlayList
            modalClose={modalHideHandler}
            contentSliderDataArr={contentSliderDataArr}
            selectedVideoIndex={videoSequenceList}
            numberOfSliders = {contentSliderDataArr.length}
            //modalStatus = {modalShow}
            videoTaggingDataGetAfterModalCloesedFun={videoTaggingDataGetAfterModalCloesedFun}
          />
        </Modal>
      )}
    </Fragment>
  );
}

export default React.memo(App);



