import React from 'react'
import styled from "styled-components";
import useWindowSize from "../hooks/useWindowSize";

function PlayListLayout(props) {
  //console.log('props.gradientColor ',props.gradientColor)
  //const drawerHeightBasedOnDrawerType = props.drawerType === 'landscape' ?  {height: '289px'} : null
  const screenStatus = useWindowSize();
  //console.log('screenStatus:------------------------------',screenStatus)
  //for Gradient overlay
  let horizontalGradient = ` 
  overflow: scroll; 
  &::after {
    content: '';
    width: ${screenStatus === 'tablet' ? '100%' : '30%'};
    height:35px;
    bottom:0;
    left: 0;
    position: absolute;
    box-shadow: inset 18px -31px 20px -6px ${props.gradientColor && props.gradientColor !== undefined ? props.gradientColor : '#fff'}
  }`;

  const Layout = styled.div`${props.numberOfSliders > 1 ? horizontalGradient: null}`;
    return (
        <Layout className={props.elClass}>
            {props.children}
        </Layout>
    )
}

export default PlayListLayout
