import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("richNavVideo"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
     <>
     <Helmet>
          <script src="https://www.youtube.com/iframe_api"></script>
      </Helmet>
      <App/>
     </>
    </HelmetProvider>
  </React.StrictMode>
);

