import React from 'react'
import styled from "styled-components";

function PlayListMobileLayout(props) {
    //console.log('play list number: ',props.numberOfSliders)
    //console.log('props.gradientColor ',props.gradientColor)
    //const drawerHeightBasedOnDrawerType = props.drawerType === 'landscape' ?  {height: '289px'} : null
  
    //for Gradient overlay
    let verticalGradient = `
    &::after {
      content: '';
      width: 35px;
    height: 17%;
    bottom: 20px;
    right: 0px;
    position: absolute;
      box-shadow: inset -23px -3px 16px -4px ${props.gradientColor && props.gradientColor !== undefined ? props.gradientColor : '#fff'}
    }`;
    //${screenStatus === "mobile" || screenStatus === "tablet" ? verticalGradient : null}
    const Layout = styled.div`${props.numberOfSliders > 1 ? verticalGradient : null}`;
      return (
          <Layout className={props.elClass} >
              {props.children}
          </Layout>
      )
}

export default PlayListMobileLayout