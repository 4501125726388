export function getTaggingFunc(alt, positionIndex, videoTagging) {
let categoryLevel = "unKnown"
 if(document.getElementById('widget_breadcrumb')){
    const el = document.getElementById('widget_breadcrumb');
    const list = el.getElementsByTagName('li');
    const num = list.length-1;
    categoryLevel =  ",L" + num;
 }
 let pageFullUrl 
   //eventCategory === 'laptop' ? eventCategory = 'desktop' : eventCategory = eventCategory
   if(document.querySelector("link[rel='canonical']")){
     pageFullUrl = document.querySelector("link[rel='canonical']").getAttribute("href");
   }
   
    
  if(String(positionIndex).includes("Arrow")){
    pageFullUrl = 'Scroll to Browse'
    categoryLevel = ''
  }


  const dataLayerInfo = {
    event: 'category_nav_widget',
    TGGEventCategory: `Category Nav Widget`,
    TGGEventAction: videoTagging ? `Video - ${videoTagging}` : `Video - ${pageFullUrl}${categoryLevel}`,
    TGGEventLabel: `${alt}, ${positionIndex}`
  }
  if(window.dataLayer){
    window.dataLayer.push(dataLayerInfo)
    //console.log(dataLayerInfo)
  }else{
    console.log(dataLayerInfo)
  }
  //window.dataLayer.push(dataLayerInfo)
  //console.log('pageFullUrl: ', pageFullUrl)
  //console.log('categoryLevel: ',categoryLevel)
  //console.log('Alt: ', alt)
  //console.log('positionIndex: ',positionIndex)
  
}

export function getYTVideoPlayTaggingFunc(x,y,z) {
  var ceilValue
  if(z <= 25){
    ceilValue = 0
  }else if(z > 25 && z <= 50){
    ceilValue = 25
  }else if(z >50 && z <= 75){
    ceilValue = 50
  }else if(z > 75 && z < 100){
    ceilValue = 75
  }else if(z === 100){
    ceilValue = 100
  }
 
  //console.log(x)
    const dataLayerInfo = {
      event: 'modal_youtube_richnav',
      TGGEventCategory: 'Category Nav Widget',
      TGGEventAction: `Video ${y}  ${ceilValue}%`,
      TGGEventLabel: `${x}`
    }
    if(window.dataLayer){
      window.dataLayer.push(dataLayerInfo)
      //console.log(dataLayerInfo)
    }else{
      console.log(dataLayerInfo)
    }
  }


