import { useState, useEffect } from "react";
// The Hook for send msg(boolean or msg) to commonent based on sceen size, in this project for show/hide arrow icon and suggestion list
//a Util function that will conver the absolute width into breakpoints
function getBreakPoint(windowWidth) {
    //console.log(windowWidth < 768 && windowWidth > 464)
    if (windowWidth) {
        //console.log(windowWidth)
        if (windowWidth < 3000 && windowWidth > 1024) {
            return 'desktop'
        }else if(windowWidth <= 1024 && windowWidth > 768){
            return 'laptop'
        }else if(windowWidth <= 768 && windowWidth > 429){
            return 'tablet'
        }
        else if(windowWidth <= 429 && windowWidth > 0){
            return 'mobile'
        }
    }
}

function useWindowSize() {
    const isWindowClient = typeof window === "object";

    const [windowSize, setWindowSize] = useState(
        isWindowClient
            ? getBreakPoint(window.innerWidth)
            : undefined
    );

    useEffect(() => {
        //a handler which will be called on change of the screen resize
        function setSize() {
            setWindowSize(getBreakPoint(window.innerWidth));
        }

        if (isWindowClient) {
            //register the window resize listener
            window.addEventListener("resize", setSize);

            //unregister the listerner on destroy of the hook
            return () => window.removeEventListener("resize", setSize);
        }
    }, [isWindowClient, setWindowSize]);
    return windowSize;
}

export default useWindowSize;