import React from "react";
import { getTaggingFunc } from "../../helpers/dataLayerData";
import "./videoImages.css";

function VideoImage(props) {
  //console.log('props.videoDescription: ', props.videoDescription)

  return (
    <div>
      <div className="videoImageWrapper">
        <img
          className="noSelect lazy videoImage"
          src={props.imageUrl}
          alt={props.imageAlt}
          loading="lazy"
          style={{ height: "94px", width: "167px" }}
          onClick={()=>{
            props.selectedVideoIndex(props.videoUrl, props.videoDescription)
            getTaggingFunc(props.videoDescription,props.position + 1,'Thumbnail Click')
          }
          }
        />
        <div className="videoInfo">
          <p>{props.videoDescription}</p>
          <a href={props.videoModalURL}  rel="noreferrer" target="_blank" onClick={()=>getTaggingFunc(props.videoDescription+'-'+props.videoModalText+'-'+props.videoModalURL,props.position + 1,'Description Click')}>{props.videoModalText}</a>
          {props.videoTime && <p>({props.videoTime} min)</p>}
        </div>
      </div>
    </div>
  );
}

export default VideoImage;

